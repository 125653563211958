import { DeleteOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  CompetitionQuery,
  CompetitionQueryVariables,
  CompetitionsCountQuery,
  CompetitionsCountQueryVariables,
  CompetitionsQuery,
  CompetitionsQueryVariables,
  DeleteCompetitionMutation,
  DeleteCompetitionMutationVariables,
  FilterCompetitionInput,
  WriteCompetitionInput,
  WriteCompetitionMutation,
  WriteCompetitionMutationVariables,
} from '@cobra/common/dist/graphql/generated/graphql';
import { CompetitionMutations } from '@cobra/common/dist/graphql/mutations';
import { CompetitionQueries } from '@cobra/common/dist/graphql/queries';
import {
  CompetitionTypes,
  REGIONS_AND_COUNTRIES,
} from '@cobra/common/dist/utils/constants';
import { css } from '@emotion/css';
import { Button, Popconfirm, Tag } from 'antd';
import { isEmpty, isNil } from 'ramda';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFormState } from '../../../hooks/useFormState';
import { usePagination } from '../../../hooks/usePagination';
import { Layout } from '../../Layout';
import { CompetitionConfigForm } from './config';
import { CompetitionsFilters } from './filters';
import { CompetitionForm } from './form';

const initialCompetitionData: WriteCompetitionInput = {
  active: false,
  config: {},
  groups: '',
  logoUrl: '',
  name: '',
  phases: '',
  region: '',
  sport: '',
  type: CompetitionTypes.Tournament,
};

export const CompetitionsView = () => {
  // Declare state
  // const id = useIDParam();
  const { limit, offset, goTo, changeSize } = usePagination();
  const [searchParams, setSearchParams] = useSearchParams();
  const dataId = searchParams.get('id');
  const [filter, setFilter] = useState<FilterCompetitionInput>({});
  const [data, { reset, update, changed, ...formMethods }] = useFormState(
    initialCompetitionData
  );

  // Declare queries and mutations
  const {
    data: { competitionsCount: competitionCount } = { competitionsCount: 0 },
    refetch: count,
  } = useQuery<CompetitionsCountQuery, CompetitionsCountQueryVariables>(
    CompetitionQueries.COMPETITIONSCOUNT
  );

  const {
    data: { competitions } = { competitions: [] },
    loading,
    refetch,
  } = useQuery<CompetitionsQuery, CompetitionsQueryVariables>(
    CompetitionQueries.COMPETITIONS,
    {
      variables: { limit: 20, offset: 0 },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => console.log(data),
    }
  );

  const [getCompetition] = useLazyQuery<
    CompetitionQuery,
    CompetitionQueryVariables
  >(CompetitionQueries.COMPETITION, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: ({ competition }) => {
      delete competition.createdAt;
      delete competition.fixtures;
      delete competition.teams;
      reset(competition as WriteCompetitionInput);
    },
  });

  const [deleteCompetition] = useMutation<
    DeleteCompetitionMutation,
    DeleteCompetitionMutationVariables
  >(CompetitionMutations.REMOVE, {
    onCompleted: () => refetch(),
  });

  const [saveCompetition] = useMutation<
    WriteCompetitionMutation,
    WriteCompetitionMutationVariables
  >(CompetitionMutations.WRITE, {
    onCompleted: ({ writeCompetition }) => {
      reset(data);
      if (dataId === 'new') {
        setSearchParams((p) => {
          p.set('id', writeCompetition.id);
          return p;
        });
      }
    },
  });

  // Trigger effects
  useEffect(() => {
    refetch({ options: filter, offset, limit });
    count({ options: filter });
  }, [offset, limit, filter]);

  useEffect(() => {
    if (isNil(dataId) || isEmpty(dataId) || dataId === 'new') {
      reset(initialCompetitionData);
    } else {
      getCompetition({ variables: { id: dataId! } });
    }
  }, [dataId]);

  return (
    <Layout<(typeof competitions)[0]>
      style={style}
      table={{
        data: competitions,
        pagination: {
          pageSize: limit,
          total: competitionCount,
          onSizeChange: (p, s) => changeSize(s),
          onChange: (page) => goTo(page),
        },
        loading,
        columns: [
          {
            title: 'Logo',
            dataIndex: 'logoUrl',
            render: (term, record) =>
              term ? (
                <img
                  src={term}
                  alt={`${record.name} logo`}
                  className="competition-logo"
                />
              ) : null,
          },
          { title: 'Nombre', dataIndex: 'name' },
          { title: 'Deporte', dataIndex: 'sport' },
          {
            title: 'Región',
            dataIndex: 'region',
            render: (x) => {
              const full = REGIONS_AND_COUNTRIES[x] ?? '';
              const val = full.substring(0, 10);
              const suffix = val === full ? '' : '...';
              return <Tag>{val + suffix}</Tag>;
            },
          },
          {
            title: 'Activa',
            dataIndex: 'active',
            render: (x) => (x ? '✔️' : '❌'),
          },
          {
            title: 'Acciones',
            render: (t, x) => (
              <Popconfirm
                title="¿Desea borrar esta competencia permanentemente?"
                onConfirm={async (e) => {
                  if (!e) return;
                  e.preventDefault();
                  e.stopPropagation();
                  await deleteCompetition({ variables: { id: x.id } });
                }}
              >
                <Button icon={<DeleteOutlined />} />
              </Popconfirm>
            ),
          },
        ],
        onRowClick: (x) => {
          if (x.id === dataId) {
            setSearchParams((p) => {
              p.delete('id');
              return p;
            });
          } else {
            setSearchParams((p) => {
              p.set('id', x.id);
              return p;
            });
          }
        },
      }}
      filters={
        <CompetitionsFilters
          onChange={setFilter}
          openModal={() =>
            setSearchParams((p) => {
              p.set('id', 'new');
              return p;
            })
          }
        />
      }
      modal={{
        state: !isNil(dataId) && !isEmpty(dataId),
        title: !dataId || dataId === 'new' ? 'Nueva competencia' : data.name!,
        disabled: !changed,
        onCancel: () =>
          setSearchParams((p) => {
            p.delete('id');
            return p;
          }),
        onOk: async () => {
          saveCompetition({
            variables: { data: formMethods.difference({ keep: ['id'] }) },
          });
        },
        element: (
          <>
            <CompetitionForm data={data} onChange={update} />
            <CompetitionConfigForm
              data={data}
              onChange={(config) => update({ config })}
            />
          </>
        ),
      }}
    />
  );
};

const style = css`
  .competition-logo {
    aspect-ratio: 1;
    width: 3rem;
    height: 3rem;
  }
`;
